import Layout from "./src/components/Layout";
import { useStaticQueryDataChatMutuo } from "./src/query/useStaticQueryDataChatMutuo";
import "./src/sass/main.scss";
import {
  CHAT_MUTUO_HIDE_OLD_QUESTIONS,
  CHAT_MUTUO_IS_WIZARD_VISIBLE,
} from "./src/state/actions/chatmutuo.actions";
import {
  CHAT_MUTUO_SET_MESSAGES_CLIENT_ID,
  CHAT_MUTUO_SET_MESSAGES_UID,
} from "./src/state/actions/chatmutuo.actions";
import {
  CONFIG_ADD_GCLID,
  CONFIG_ADD_MSMLID,
  CONFIG_ADD_QUERY_STRING_VALUES,
  CONFIG_ADD_UTMCHATBOT,
} from "./src/state/actions/config.actions";
import { getChatMutuo } from "./src/state/selectors/chatmutuo.selector";
import store from "./src/state/store";
import { CHAT_MUTUO_FETCH_HISTORY } from "./src/state/thunk/chatmutuo.thunk";
import { FETCH_HISTORY } from "./src/state/thunk/history.thunk";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import Cookies from "universal-cookie";

async function initialSetupConfigAppAsync() {
  if (typeof window !== "undefined") {
    const cookie = new Cookies();
    const cookies = cookie.get("user-mutui-history");
    if (cookies) {
      cookies.forEach((element) => {
        store.dispatch(FETCH_HISTORY(element));
      });
    }
    const params = new URLSearchParams(window.location.search);
    const gclid = params.get("gclid");
    if (gclid) {
      store.dispatch(CONFIG_ADD_GCLID(gclid.trim()));
    }
    const msmlid = params.get("msmlid");
    if (msmlid) {
      store.dispatch(CONFIG_ADD_MSMLID(msmlid.trim()));
    }
    const utmchatbot = params.get("utm_chatbot");
    if (utmchatbot) {
      store.dispatch(CONFIG_ADD_UTMCHATBOT(utmchatbot.trim()));
    }
    if (params?.size > 0) {
      const queryStringValues = {};
      for (const [key, value] of params.entries()) {
        queryStringValues[key] = value;
      }
      if (queryStringValues) {
        store.dispatch(CONFIG_ADD_QUERY_STRING_VALUES(queryStringValues));
      }
    }
    let gaClientID = cookie.get("_ga");
    if (gaClientID) {
      gaClientID = gaClientID.slice(6);
      store.dispatch(CHAT_MUTUO_SET_MESSAGES_CLIENT_ID(gaClientID));
    }
    const chatUID = cookie.get("chatmutuo-uid");
    if (chatUID) {
      store.dispatch(CHAT_MUTUO_SET_MESSAGES_UID(chatUID));
    }
    if (chatUID || gaClientID) {
      store.dispatch(
        CHAT_MUTUO_FETCH_HISTORY({
          uid: chatUID,
          clientId: gaClientID,
        })
      );
    }
  }
}

export const onClientEntry = () => {
  initialSetupConfigAppAsync();
};

function InitialSetupConfigApp({ children }) {
  const {
    chatMutuo: {
      wizard: { abilita },
    },
  } = useStaticQueryDataChatMutuo();

  const {
    messages: { data },
  } = useSelector(getChatMutuo);
  // console.log("INITIAL SETUP data:")
  // console.log(data)

  useEffect(() => {
    // initialSetupConfigAppAsync()

    // const cookie = new Cookies()
    // const cookies = cookie.get("user-mutui-history")

    // let gaClientID = cookie.get("_ga")
    // if (gaClientID) {
    // 	gaClientID = gaClientID.slice(6)
    // 	store.dispatch(CHAT_MUTUO_SET_MESSAGES_CLIENT_ID(gaClientID))
    // }
    // const chatUID = cookie.get("chatmutuo-uid")
    // if (chatUID) {
    // 	store.dispatch(CHAT_MUTUO_SET_MESSAGES_UID(chatUID))
    // }
    // if (chatUID || gaClientID) {
    // 	store.dispatch(
    // 		CHAT_MUTUO_FETCH_HISTORY({
    // 			uid: chatUID,
    // 			clientId: gaClientID,
    // 		})
    // 	)
    // }

    store.dispatch(CHAT_MUTUO_IS_WIZARD_VISIBLE(abilita ? true : false));
    if (data.length > 0) {
      store.dispatch(CHAT_MUTUO_IS_WIZARD_VISIBLE(false));
      store.dispatch(CHAT_MUTUO_HIDE_OLD_QUESTIONS());
    }
  }, []);
  return <>{children}</>;
}

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <InitialSetupConfigApp>{element}</InitialSetupConfigApp>
    </Provider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  if (element?.key?.includes("404.html")) {
    return element;
  }
  const {
    pageContext: { isWidget = false },
  } = props;
  if (isWidget) {
    return <main className="__iframe">{element}</main>;
  }
  return <Layout {...props}>{element}</Layout>;
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    createRoot(container).render(element);
  };
};

// export const shouldUpdateScroll = ({
// 	routerProps: { location },
// 	getSavedScrollPosition,
// }) => {
// 	const currentPosition = getSavedScrollPosition(location)
// 	setTimeout(() => {
// 		window.scrollTo(...(currentPosition || [0, 0]))
// 	}, 0)
// 	return false
// }

export const shouldUpdateScroll = ({
  routerProps: { location: currentLocation = "" },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = "manual";
  const currentPosition = getSavedScrollPosition(
    currentLocation,
    currentLocation.key
  );

  // FAKE MODAL (VERIFICA || MUTUO) === INSTANT SCROLL
  const { pathname: prevPathname = "" } = prevRouterProps?.location || {};
  // console.log('CURRENT --> ', currentLocation.pathname)
  // console.log('PREV --> ', prevPathname)
  const prevIsFakeModal = new RegExp(/\/verifica\/|\/mutuo\//);
  if (prevPathname.match(prevIsFakeModal)) {
    document.documentElement.style.scrollBehavior = "auto";
  }

  // GLOSSARIO
  const glossarioRegex = new RegExp(/^\/glossario-mutui\/$/);
  if (currentLocation.pathname.match(glossarioRegex)) {
    return false;
  }

  if (!currentPosition) {
    window.scrollTo(0, 0);
  } else {
    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        window.scrollTo(...currentPosition);
      });
    }, 0);
  }
  return false;
};
