import { MSMRequest } from "../../../../api/msm";
import CustomReactSelect from "../../../CustomReactSelect";
import Text from "../../../Text";
import * as LavoraConNoiStyles from "./lavora-con-noi.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { Email, domains } from "@smastrom/react-email-autocomplete";
import classNames from "classnames";
import parse from "html-react-parser";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { IMaskInput } from "react-imask";
import * as yup from "yup";
import "yup-phone-lite";

const LISTA_POSIZIONI = [
  { value: "140", label: "Consulente telefonico mutui" },
];
const MAX_FILE_SIZE = 500000;

const reactEmailAutocompleteClassList = {
  wrapper: "my-wrapper",
  input: "my-input",
  dropdown: "my-dropdown",
  suggestion: "my-suggestion",
  username: "my-username",
  domain: "my-domain",
};

const baseList = [
  "gmail.com",
  "alice.it",
  "tiscali.it",
  "libero.it",
  "virgilio.it",
  "live.it",
  "hotmail.com",
  "yahoo.com",
  "outlook.com",
];

const schemaLavoraConNoi = yup.object().shape({
  TitoloOfferta: yup
    .string()
    .oneOf(
      LISTA_POSIZIONI.map((posizione) => posizione.value),
      "Il campo 'Posizione' è obbligatorio"
    )
    .required("Il campo 'Posizione' è obbligatorio"),
  Nome: yup
    .string()
    .min(2, 'Il campo "Nome" deve contenere almeno 2 caratteri')
    .trim()
    .required('Il campo "Nome" è obbligatorio')
    .matches(/^[aA-zZ\s]+$/, 'Il campo "Nome" può contenere solo lettere'),
  Cognome: yup
    .string()
    .min(2, 'Il campo "Cognome" deve contenere almeno 2 caratteri')
    .trim()
    .required('Il campo "Cognome" è obbligatorio')
    .matches(/^[aA-zZ\s]+$/, 'Il campo "Cognome" può contenere solo lettere'),
  Eta: yup
    .number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(18, 'Il campo "Età" deve essere maggiore di 18 anni')
    .max(100, 'Il campo "Età" deve essere minore di 100 anni')
    .required('Il campo "Età" è obbligatorio'),
  Cellulare: yup
    .string()
    .phone("IT", false, 'Il campo "Cellulare" deve essere un numero valido')
    .required('Il campo "Cellulare" è obbligatorio'),
  TelefonoFisso: yup
    .string()
    .phone("IT", 'Il campo "Telefono Fisso" deve essere un numero valido')
    .nullable()
    .optional(),
  Email: yup.string().email().required('Il campo "Email" è obbligatorio'),
  CV: yup.object().shape({
    Nome: yup.string().nullable().optional(),
    Estensione: yup.string().nullable().optional(),
    Contenuto: yup
      .mixed()
      .test("required", 'Il file del campo "CV" è obbligatorio', (file) => {
        if (file) return true;
        return false;
      })
      .test(
        "fileSize",
        'Il file del campo "CV" deve essere minore di 500kb',
        (file) => {
          return file && file.size <= MAX_FILE_SIZE;
        }
      )
      .test(
        "fileType",
        'Il file del campo "CV" deve essere un file PDF',
        (file) => {
          return file && file.type === "application/pdf";
        }
      ),
  }),
  Note: yup.string().nullable().optional(),
  AccettaTrattamentoDati: yup
    .boolean()
    .oneOf([true], "Devi accettare la privacy policy per inviare il modulo"),
});

function LavoraConNoi({ labelCta, consenso, setSettingsModal }) {
  const [filename, setFilename] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schemaLavoraConNoi),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      TitoloOfferta: "140",
      Nome: "",
      Cognome: "",
      Eta: "",
      Cellulare: "",
      TelefonoFisso: "",
      Email: "",
      Note: "",
      AccettaTrattamentoDati: false,
      CV: {
        Nome: "CV-Demo",
        Estensione: ".pdf",
        Contenuto: "",
      },
    },
  });

  const watchs = watch();
  const onSubmit = async (data) => {
    const payloadTransformations = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(data.CV.Contenuto);
      reader.onload = () =>
        resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
      reader.onerror = (error) => reject(error);
    }).then((result) => {
      return {
        ...data,
        CV: {
          ...data.CV,
          Contenuto: result,
        },
      };
    });
    const response = await MSMRequest(
      "LavoraConNoi",
      await payloadTransformations
    );
    const BodyContent = <Text content={response.msg} />;
    setSettingsModal({
      title: response.success ? "Grazie" : "Attenzione",
      content: BodyContent,
    });
    if (response.success) {
      reset();
      setFilename("");
    }
  };

  const onError = (errorsMessages) => {
    const merge = {
      ...errorsMessages,
      CV: { ...errorsMessages?.CV?.Contenuto },
    };
    const BodyContent = (
      <>
        {Object.keys(merge).map((key, index) => {
          return <p key={index}>{merge[key]?.message}</p>;
        })}
      </>
    );
    setSettingsModal({
      title: "Attenzione",
      content: BodyContent,
    });
  };

  return (
    <Form className={LavoraConNoiStyles.form} autoComplete="off">
      <Row className={LavoraConNoiStyles.formRow}>
        <Col md={12}>
          <Row>
            <Col md={4}>
              <div
                className={classNames(LavoraConNoiStyles.field, {
                  ["select-error"]: errors.TitoloOfferta,
                  ["select-valid"]:
                    !errors.TitoloOfferta && watchs.TitoloOfferta?.length > 0,
                })}
              >
                <label
                  htmlFor="TitoloOfferta"
                  className={classNames({
                    [LavoraConNoiStyles.filled]:
                      watchs.TitoloOfferta?.length > 0,
                  })}
                >
                  Posizione*
                </label>
                <Controller
                  name="TitoloOfferta"
                  control={control}
                  render={({ field }) => (
                    <CustomReactSelect
                      form
                      onChange={(e) => {
                        if (e.value) {
                          field.onChange(e.value);
                        }
                      }}
                      options={LISTA_POSIZIONI}
                      defaultValue={LISTA_POSIZIONI[0]}
                      className="react-select-form react-select-form-m-0"
                      classNamePrefix="react-select-form"
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <div
            className={classNames(LavoraConNoiStyles.field, {
              [LavoraConNoiStyles.fieldError]: errors.Nome,
              [LavoraConNoiStyles.fieldValid]:
                !errors.Nome && watchs.Nome?.length > 0,
            })}
          >
            <label
              htmlFor="Nome"
              className={classNames({
                [LavoraConNoiStyles.filled]: watchs.Nome?.length > 0,
              })}
            >
              Nome*
            </label>
            <input type="text" {...register("Nome")} />
          </div>
        </Col>
        <Col md={4}>
          <div
            className={classNames(LavoraConNoiStyles.field, {
              [LavoraConNoiStyles.fieldError]: errors.Cognome,
              [LavoraConNoiStyles.fieldValid]:
                !errors.Cognome && watchs.Cognome?.length > 0,
            })}
          >
            <label
              htmlFor="Cognome"
              className={classNames({
                [LavoraConNoiStyles.filled]: watchs.Cognome?.length > 0,
              })}
            >
              Cognome*
            </label>
            <input type="text" {...register("Cognome")} />
          </div>
        </Col>
        <Col md={2}>
          <div
            className={classNames(LavoraConNoiStyles.field, {
              [LavoraConNoiStyles.fieldError]: errors.Eta,
              [LavoraConNoiStyles.fieldValid]:
                !errors.Eta && watchs.Eta?.length > 0,
            })}
          >
            <label
              htmlFor="Eta"
              className={classNames({
                [LavoraConNoiStyles.filled]: watchs.Eta?.length > 0,
              })}
            >
              Eta*
            </label>
            <Controller
              name="Eta"
              control={control}
              valueAsNumber={true}
              render={({ field }) => (
                <IMaskInput
                  mask={[
                    {
                      mask: "00",
                      maxLength: 2,
                    },
                  ]}
                  defaultValue={String(field.value)}
                  unmask={true}
                  onAccept={(value, mask) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
        </Col>
        <Col md={4}>
          <div
            className={classNames(LavoraConNoiStyles.field, {
              [LavoraConNoiStyles.fieldError]: errors.Email,
              [LavoraConNoiStyles.fieldValid]:
                !errors.Email && watchs.Email?.length > 0,
            })}
          >
            <label
              htmlFor="Email"
              className={classNames({
                [LavoraConNoiStyles.filled]: watchs.Email?.length > 0,
              })}
            >
              E-mail*
            </label>
            <Controller
              name="Email"
              control={control}
              render={({ field }) => (
                <Email
                  classNames={reactEmailAutocompleteClassList}
                  baseList={baseList}
                  inputmode="email"
                  onChange={(newValue) => field.onChange(newValue)} // or (newValue) => customSetter(newValue)
                  value={field.value}
                  refineList={domains}
                />
              )}
            />
          </div>
        </Col>
        <Col md={4}>
          <div
            className={classNames(LavoraConNoiStyles.field, {
              [LavoraConNoiStyles.fieldError]: errors.Cellulare,
              [LavoraConNoiStyles.fieldValid]:
                !errors.Cellulare && watchs.Cellulare?.length > 0,
            })}
          >
            <label
              htmlFor="Cellulare"
              className={classNames({
                [LavoraConNoiStyles.filled]: watchs.Cellulare?.length > 0,
              })}
            >
              Cellulare*
            </label>
            <Controller
              name="Cellulare"
              control={control}
              valueAsNumber={true}
              render={({ field }) => (
                <IMaskInput
                  mask={Number}
                  unmask={true}
                  defaultValue={String(field.value)}
                  onAccept={(value, mask) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className={LavoraConNoiStyles.field}>
            <label
              htmlFor="TelefonoFisso"
              className={classNames({
                [LavoraConNoiStyles.filled]: watchs.TelefonoFisso?.length > 0,
              })}
            >
              Telefono Fisso
            </label>
            <Controller
              name="TelefonoFisso"
              control={control}
              valueAsNumber={true}
              render={({ field }) => (
                <IMaskInput
                  mask={Number}
                  unmask={true}
                  defaultValue={String(field.value)}
                  onAccept={(value, mask) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
        </Col>
        <Col md={12}>
          <div
            className={classNames(LavoraConNoiStyles.fieldFile, {
              [LavoraConNoiStyles.fieldFileError]:
                errors?.CV?.Contenuto?.message?.length > 0,
              [LavoraConNoiStyles.fieldFileValid]:
                !errors?.CV?.Contenuto?.message?.length > 0 &&
                watchs?.CV?.Contenuto?.name?.length > 0,
            })}
          >
            <label>
              CV <em>(in formato PDF)</em>*
              <div>
                <span>Scegli file</span>
                <span>
                  {filename?.length > 0 ? filename : "Nessun file selezionato"}
                </span>
              </div>
              <Controller
                name="CV.Contenuto"
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => {
                      if (e.target.files[0]) {
                        field.onChange(e.target.files[0]);
                        setFilename(e.target.files[0].name);
                        setValue(
                          "CV.Nome",
                          e.target.files[0].name.replace(".pdf", "")
                        );
                      }
                    }}
                    defaultValue={field.value}
                  />
                )}
              />
            </label>
          </div>
        </Col>
        <Col md={12}>
          <div className={LavoraConNoiStyles.field}>
            <textarea {...register("Note")} placeholder="Messaggio" />
          </div>
        </Col>
        <Col md={12}>
          <div
            className={classNames(LavoraConNoiStyles.fieldCheckbox, {
              [LavoraConNoiStyles.fieldError]: errors?.AccettaTrattamentoDati,
            })}
          >
            <input type="checkbox" {...register("AccettaTrattamentoDati")} />
            <label
              htmlFor="AccettaTrattamentoDati"
              className={LavoraConNoiStyles.smallText}
            >
              {parse(consenso.label, {
                replace: (domNode) => {
                  if (
                    domNode.attribs &&
                    domNode.attribs["data-config"] &&
                    domNode.attribs["data-config"].length > 0
                  ) {
                    const dataConfigParsed = JSON.parse(
                      domNode.attribs["data-config"]
                    );
                    switch (dataConfigParsed.type) {
                      case "open-popup":
                        return (
                          <button
                            className={LavoraConNoiStyles.trigger}
                            onClick={(e) => {
                              e.preventDefault();
                              const BodyContent = (
                                <>
                                  <Text
                                    content={consenso.popup.testo}
                                    removeP={false}
                                  />
                                </>
                              );
                              setSettingsModal({
                                title: consenso.popup.titolo,
                                content: BodyContent,
                              });
                            }}
                          >
                            {dataConfigParsed.label}
                          </button>
                        );
                      default:
                        break;
                    }
                  }
                },
              })}
            </label>
          </div>
        </Col>
        <Col md={12}>
          <button
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
            className={LavoraConNoiStyles.button}
            disabled={isSubmitting}
          >
            {labelCta}
          </button>
        </Col>
      </Row>
    </Form>
  );
}

export default LavoraConNoi;
