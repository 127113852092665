import { createSelector } from "@reduxjs/toolkit";

export const getShow = createSelector(
  (state) => state.chatmutuo.show,
  (state) => state
);

export const getShowWizard = createSelector(
  (state) => state.chatmutuo.showWizard,
  (state) => state
);

export const getShowChat = createSelector(
  (state) => state.chatmutuo.showChat,
  (state) => state
);

export const getWizardStep = createSelector(
  (state) => state.chatmutuo.wizardStep,
  (state) => state
);

export const getMessages = createSelector(
  (state) => state.chatmutuo.messages,
  (state) => state
);

export const getNumeroRisposta = createSelector(
  (state) => state.chatmutuo.numeroRisposta,
  (state) => state
);

export const getMaxRisposte = createSelector(
  (state) => state.chatmutuo.maxRisposte,
  (state) => state
);

export const getShowQuestions = createSelector(
  (state) => state.chatmutuo.hideQuestions,
  (state) => state
);

export const getChatMutuo = createSelector(
  getShow,
  getShowWizard,
  getShowChat,
  getWizardStep,
  getMessages,
  getNumeroRisposta,
  getMaxRisposte,
  getShowQuestions,
  (
    show,
    showWizard,
    showChat,
    wizardStep,
    messages,
    numeroRisposta,
    maxRisposte,
    hideQuestions
  ) => ({
    show,
    showWizard,
    showChat,
    wizardStep,
    messages,
    numeroRisposta,
    maxRisposte,
    hideQuestions,
  })
);
