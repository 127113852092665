import { createSelector } from "@reduxjs/toolkit";

export const getClickChat = createSelector(
  (state) => state.config.settings.clickChat,
  (state) => state
);

export const getUtmChatbot = createSelector(
  (state) => state.config.settings.utmchatbot,
  (state) => state
);

export const getGclid = createSelector(
  (state) => state.config.settings.gclid,
  (state) => state
);

export const getMsmlid = createSelector(
  (state) => state.config.settings.msmlid,
  (state) => state
);

export const getSessionId = createSelector(
  (state) => state.config.settings.sessionFeId,
  (state) => state
);

export const getQueryStringValues = createSelector(
  (state) => state.config.settings.queryStringValues,
  (state) => state
);
