import { MSMRequest } from "../api/msm";
import { saveAs } from "file-saver";
import DOMPurify from "isomorphic-dompurify";
import isString from "lodash/isString";
import slugify from "slugify";

export const flatMenuListToHierarchical = (
  data = [],
  { idKey = "id", parentKey = "parentId", childrenKey = "children" } = {}
) => {
  const tree = [];
  const childrenOf = {};
  data.forEach((item) => {
    const newItem = { ...item };
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
    childrenOf[id] = childrenOf[id] || [];
    newItem[childrenKey] = childrenOf[id];
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem);
  });

  const treeChildrenReorder = tree.reduce((acc, item) => {
    if (item[childrenKey].length > 0) {
      acc.push({
        ...item,
        [childrenKey]: item[childrenKey].sort((a, b) => a.order - b.order),
      });
    } else {
      acc.push(item);
    }
    acc.sort((a, b) => a.order - b.order);
    return acc;
  }, []);

  return treeChildrenReorder;
};

export const removePTag = (html) => {
  if (html && isString(html)) {
    return DOMPurify.sanitize(html, {
      FORBID_TAGS: ["p"],
      ADD_ATTR: ["target"],
    }).trim();
  }
  return "";
};

export const removeDivTag = (html) => {
  if (html && isString(html)) {
    return DOMPurify.sanitize(html, {
      FORBID_TAGS: ["div"],
      ADD_ATTR: ["target"],
    }).trim();
  }
  return "";
};

export const removeAllTags = (html) => {
  if (html && isString(html)) {
    return DOMPurify.sanitize(html, { ALLOWED_TAGS: ["#text"] }).trim();
  }
  return "";
};

export const removeBRTag = (html) => {
  if (html && isString(html)) {
    return DOMPurify.sanitize(html, {
      ALLOWED_TAGS: ["strong"],
      ADD_ATTR: ["target"],
    }).trim();
  }
  return "";
};

export const currencyCleaner = (currency, prefix) => {
  if (currency && prefix && isString(currency)) {
    return currency
      .replace(prefix, "")
      .replace(".", "")
      .replace(",", "")
      .trim();
  }
  return "";
};

export const groupArrayOfObjects = (list, key) => {
  return list.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const currencyFormat = (value, fixed = 2) => {
  let fixedtoString = fixed.toString();
  return new Intl.NumberFormat("it-IT", {
    currency: "EUR",
    minimumFractionDigits: fixedtoString,
    maximumFractionDigits: fixedtoString,
  }).format(value);
};

export const percentageFormat = (num, fixed = 2) => {
  return Number(num / 100).toLocaleString("it-IT", {
    currency: "EUR",
    style: "percent",
    minimumFractionDigits: fixed,
  });
};

// The above code uses RegEx to transform the letters. The RegEx might look confusing, so let me explain what happens:
// ^ matches the beginning of the string.
// \w matches any word character.
// {1} takes only the first character.
// Thus, ^\w{1} matches the first letter of the word.
// | works like the boolean OR. It matches the expression after and before the |.
// \s+ matches any amount of whitespace between the words (for example spaces, tabs, or line breaks).
export const titleCase = (str) => {
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export const runtimeDataReplacerDM = (text) => {
  if (text && isString(text)) {
    return text.replace(
      "@@runtime-data-g-m@@",
      new Date().toLocaleDateString("it-IT", {
        month: "long",
        day: "numeric",
      })
    );
  }
  return text;
};

export const runtimeDataReplacer = (text) => {
  if (text && isString(text)) {
    return text.replace(
      "@@runtime-data@@",
      new Date().toLocaleDateString("it-IT", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    );
  }
  return text;
};

export const runtimeBankNameReplacer = (text, nome) => {
  if (text && isString(text) && nome && isString(nome)) {
    return text.replace(/@@nome-banca@@/g, nome);
  }
  return "questa banca";
};

export async function requestDownloadPDF(endpoint, postData) {
  let response = await MSMRequest(endpoint, postData);
  return response?.FileStream?._buffer || null;
}

export async function downloadPDFfromBuffer(buffer, fileName) {
  let binary = atob(buffer.replace(/\s/g, ""));
  let len = binary.length;
  let arrayBuffer = new ArrayBuffer(len);
  let view = new Uint8Array(arrayBuffer);
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  let blob = new Blob([view], { type: "application/pdf" });
  saveAs(blob, fileName);
}

export const formatDate = (d) => {
  if (!d) {
    return "";
  }
  let date = new Date(d);
  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, "0");
  let day = String(date.getDate()).padStart(2, "0");
  return [day, month, year].join("/");
};

export const capitalized = (str) => {
  if (str && isString(str)) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`.trim();
  }
  return str;
};

export function createAnchorId(siteblocks) {
  return (
    siteblocks?.map((block) => {
      if (block?.ancora?.ancoraBlocco) {
        return {
          ...block,
          ancora: {
            ...block.ancora,
            id: slugify(block.ancora.titoloAncora, {
              lower: true,
              trim: true,
            }),
          },
        };
      }
      return block;
    }) || []
  );
}

export function createAnchorIdList(siteblocks) {
  return (
    siteblocks
      ?.filter((block) => block?.ancora?.ancoraBlocco)
      ?.map((block) => {
        return {
          id: slugify(block.ancora.titoloAncora, {
            lower: true,
            trim: true,
          }),
          title: block.ancora.titoloAncora,
        };
      }) || []
  );
}
